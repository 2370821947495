import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

// images
import QuestionIcon from './question.svg?reactCompornt'

import * as Style from './style.css'

export interface IProps
  extends Omit<React.ComponentPropsWithoutRef<'input'>, 'type' | 'className'> {
  responsive?: boolean
  tooltipText?: React.ReactNode
  wrapperRight?: number
}

export const Checkbox = React.forwardRef<HTMLInputElement, IProps>(
  ({ children, tooltipText, wrapperRight, responsive, ...props }, ref) => {
    const id = React.useId()
    const [tooltipVisible, setTooltipVisible] = React.useState(false)
    const element = React.useRef<HTMLSpanElement>(null)
    const tooltipHandler = (
      e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    ) => {
      let left = 0
      let tooltipLeft = 0
      let tooltipWidth = 0
      const clientRects = e.currentTarget?.getBoundingClientRect()
      const tooltipClientRects = element.current?.getBoundingClientRect()

      if (wrapperRight && clientRects && tooltipClientRects) {
        tooltipLeft = clientRects.left
        tooltipWidth = tooltipClientRects.width
        left = wrapperRight - tooltipLeft - tooltipWidth - 16 // 右側に余白を作る
      }
      if (left < -0) {
        element.current.style.left = `${left}px`
      }
      setTooltipVisible(!tooltipVisible)
    }

    return (
      <span className={responsive ? Style.CheckboxWide : Style.Checkbox}>
        <input
          id={id}
          className={Style.CheckboxControl}
          type="checkbox"
          {...props}
          ref={ref}
        />
        <span className={Style.CheckboxWrapper}>
          <label
            className={
              tooltipText ? Style.CheckboxLabelWithTooltip : Style.CheckboxLabel
            }
            htmlFor={id}
          >
            <span className={Style.CheckboxStatus}>
              <FontAwesomeIcon
                className={Style.CheckboxStatusIcon}
                icon={faCheck}
              />
            </span>
            {children}
          </label>
          {tooltipText && (
            <span className={Style.Tooltip}>
              <input
                type="checkbox"
                className={Style.TooltipControl}
                checked={tooltipVisible}
                readOnly={true}
              />
              <label className={Style.TooltipLabel} onClick={tooltipHandler}>
                <QuestionIcon className={Style.TooltipIcon} />
              </label>
              <span className={Style.TooltipText} ref={element}>
                {tooltipText}
              </span>
            </span>
          )}
        </span>
        {tooltipVisible && (
          <div
            className={Style.TooltipBackgroud}
            onClick={() => setTooltipVisible(!tooltipVisible)}
          ></div>
        )}
      </span>
    )
  },
)

export default Checkbox
