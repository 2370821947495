import $ from 'jquery'

const getAuthenticityToken = () => {
  const authenticityToken = ($('[name="csrf-token"]')[0] as HTMLMetaElement)
    .content
  return authenticityToken
}

export const get = async <T>(url: string, data?: object) => {
  const authenticityToken = getAuthenticityToken()

  try {
    const res = (await $.ajax({
      url,
      data: {
        authenticity_token: authenticityToken,
        ...data,
      },
    })) as T

    return { result: true, res }
  } catch (error: unknown) {
    return { result: false, error }
  }
}

export const post = async <T>(url: string, data?: object) => {
  const authenticityToken = getAuthenticityToken()

  try {
    const res = (await $.ajax({
      method: 'POST',
      url,
      data: {
        authenticity_token: authenticityToken,
        ...data,
      },
    })) as T

    return { result: true, res }
  } catch (error: unknown) {
    return { result: false, error }
  }
}
