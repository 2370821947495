import {
  parseISO,
  format,
  isBefore,
  isSameDay,
  getYear,
  getMonth,
  getDate,
  differenceInMonths,
} from 'date-fns'
import ja from 'date-fns/locale/ja'

// 2023年03月07日(火) 06:00 - 12:00
export const formatDatetimeDurationLong = (
  startAt: string,
  endAt: string,
): string => {
  const start = parseISO(startAt)
  const end = parseISO(endAt)
  const startFormat = 'yyyy年MM月dd日(E) HH:mm'
  const endFormat = isSameDay(start, end) ? 'HH:mm' : '翌 HH:mm'
  return `${format(start, startFormat, { locale: ja })} - ${format(
    end,
    endFormat,
    { locale: ja },
  )}`
}

// 03月07日(火) 06:00 - 12:00
export const formatDatetimeDurationShort = (
  startAt: string,
  endAt: string,
): string => {
  const start = parseISO(startAt)
  const end = parseISO(endAt)
  const startFormat = 'MM月dd日(E) HH:mm'
  const endFormat = isSameDay(start, end) ? 'HH:mm' : '翌 HH:mm'
  return `${format(start, startFormat, { locale: ja })} - ${format(
    end,
    endFormat,
    { locale: ja },
  )}`
}

export const parseDateString = (dateString: string) => {
  const date = parseISO(dateString)

  return {
    year: getYear(date),
    month: getMonth(date) + 1, // ライブラリの仕様として1月が0からとなる
    date: getDate(date),
  }
}

export const calcAgeString = (year?: number, month?: number, day?: number) => {
  if (!(year && month && day)) {
    return undefined
  }

  const now = new Date()
  const birthDay = new Date(year, month - 1, day)

  if (isBefore(now, birthDay)) {
    return '妊娠中'
  }

  const differenceMonth = differenceInMonths(now, birthDay)
  return `${Math.floor(differenceMonth / 12)}歳 ${differenceMonth % 12}ヶ月`
}

export const dateToStringWithFormat = (date: Date, formatString: string) =>
  format(date, formatString)
