import * as React from 'react'
import { useForm, UseFormGetValues } from 'react-hook-form'

import { SearchTime as Presenter, IProps as IPresenterProps } from './presenter'
import {
  trackingUserContext,
  searchEventIdContext,
} from '@/contexts/trackingContexts'
import { trackEvent } from '@/utils/analyze'

interface IProps
  extends Omit<
    IPresenterProps,
    | 'setTimeFromHandler'
    | 'setTimeToHandler'
    | 'timeFrom'
    | 'timeTo'
    | 'resetHandler'
    | 'submitHandler'
  > {
  getValues: UseFormGetValues<ISearchTimeState>
  submitHandler: (state: ISearchTimeState) => void
}

export const SearchTime = ({ getValues, ...props }: IProps): JSX.Element => {
  const user = React.useContext(trackingUserContext)
  const eventIds = React.useContext(searchEventIdContext)
  const timeFrom =
    getValues('search.work_time_from') != ''
      ? getValues('search.work_time_from')
      : '12:00'
  const timeTo =
    getValues('search.work_time_to') != ''
      ? getValues('search.work_time_to')
      : '13:00'
  const useFormObject = useForm<ISearchTimeState>({
    defaultValues: {
      search: {
        work_time_from: timeFrom,
        work_time_to: timeTo,
      },
    },
  })
  const { setValue, watch } = useFormObject
  const watchAllFields = watch()
  const localTimeFrom = watchAllFields.search.work_time_from
  const localTimeTo = watchAllFields.search.work_time_to

  const setTimeFromHandler = (timeString: string) => {
    setValue('search.work_time_from', timeString)
  }
  const setTimeToHandler = (timeString: string) => {
    setValue('search.work_time_to', timeString)
  }

  const resetHandler = () => {
    setValue('search.work_time_to', '')
    setValue('search.work_time_from', '')
  }

  const submitHandler = () => {
    trackEvent(eventIds.SEARCH_MODAL_TIME_SUBMIT, user)
    props.submitHandler(watchAllFields)
  }

  const closeHandler = () => {
    trackEvent(eventIds.SEARCH_MODAL_TIME_CLOSE, user)
    props.closeHandler()
  }

  /*------------------ 表示イベントの計測 ------------------*/
  React.useEffect(() => {
    trackEvent(eventIds.SEARCH_MODAL_TIME_VIEW, user)
  }, [true]) // 表示時に1回だけ計測したいので、固定値を設定

  return (
    <Presenter
      {...props}
      timeFrom={localTimeFrom}
      timeTo={localTimeTo}
      setTimeFromHandler={setTimeFromHandler}
      setTimeToHandler={setTimeToHandler}
      resetHandler={resetHandler}
      submitHandler={submitHandler}
      closeHandler={closeHandler}
    />
  )
}

export default SearchTime
