import * as React from 'react'
import { UseFormGetValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { SearchType as Presenter, IProps as IPresenterProps } from './presenter'
import {
  trackingUserContext,
  searchEventIdContext,
} from '@/contexts/trackingContexts'
import { trackEvent } from '@/utils/analyze'

interface IProps
  extends Omit<
    IPresenterProps<ISearchType>,
    'searchTypes' | 'selectedSearchType'
  > {
  getValues: UseFormGetValues<ISearchTypeState>
}

export const SearchType = ({ getValues, ...props }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const user = React.useContext(trackingUserContext)
  const eventIds = React.useContext(searchEventIdContext)
  const selectedSearchType = getValues('search.search_type')
  const searchTypes = [
    {
      label: t('search:params.search.searchType.sitter'),
      value: 'sitter' as ISearchType,
    },
    {
      label: t('search:params.search.searchType.housekeeping'),
      value: 'housekeeping' as ISearchType,
    },
    {
      label: t('search:params.search.searchType.tutor'),
      value: 'tutor' as ISearchType,
    },
    {
      label: t('search:params.search.searchType.doula'),
      value: 'doula' as ISearchType,
    },
  ]

  const setSearchTypeHandler = (searchType: ISearchType) => {
    const items = [t(`search:params.search.searchType.${searchType}`)]
    trackEvent({ ...eventIds.SEARCH_FORM_CLICK_SEARCH_TYPE, items }, user)
    props.setSearchTypeHandler(searchType)
  }

  return (
    <Presenter<ISearchType>
      {...props}
      searchTypes={searchTypes}
      selectedSearchType={selectedSearchType}
      setSearchTypeHandler={setSearchTypeHandler}
    />
  )
}

export default SearchType
