import qs from 'qs'

import { dateToStringWithFormat } from '@/utils/dateFormat'

export const buildSearchQuery = ({ search }: ISearchState) => {
  const { geocode, station, ...otherSearch } = search
  const geocodes = geocode
    ? [].concat(
        ...Object.values(geocode).map((geocodes) =>
          geocodes.map((geocode) => geocode.geocode_id),
        ),
      )
    : []
  const stations = station
    ? [].concat(
        ...Object.values(station).map((stations) =>
          stations.map((station) => station.slug),
        ),
      )
    : []
  return {
    search: {
      ...otherSearch,
      geocode: { ...geocodes }, // オブジェクトで送信しないとエラーになる
      station_group_code: stations,
      work_date_from: otherSearch.work_date_from
        ? dateToStringWithFormat(otherSearch.work_date_from, 'yyyy-MM-dd')
        : undefined,
    },
  }
}

export const executeSearch = ({ search }: ISearchState) => {
  location.href = makeSearchUrl({ search })
}

export const buildRegularSearchQuery = ({
  search,
  regular_search,
}: ISearchState) => {
  const { geocode, station, ...otherSearch } = search
  const geocodes = geocode
    ? [].concat(
        ...Object.values(geocode).map((geocodes) =>
          geocodes.map((geocode) => geocode.geocode_id),
        ),
      )
    : []
  const stations = station
    ? [].concat(
        ...Object.values(station).map((stations) =>
          stations.map((station) => station.slug),
        ),
      )
    : station
  const weekTimes = {}
  const workTimeFrom = !!otherSearch.work_time_from
    ? otherSearch.work_time_from.split(':')
    : []
  const workTimeTo = !!otherSearch.work_time_to
    ? otherSearch.work_time_to.split(':')
    : []
  Object.keys(regular_search).forEach((week) => {
    const weeks = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ]
    if (weeks.includes(week) && regular_search[week]) {
      if (workTimeFrom.length) {
        weekTimes[`start_hour_${week}`] = workTimeFrom[0]
        weekTimes[`start_min_${week}`] = workTimeFrom[1]
      } else {
        weekTimes[`start_hour_${week}`] = '00'
        weekTimes[`start_min_${week}`] = '00'
      }
      if (workTimeTo.length) {
        weekTimes[`end_hour_${week}`] = workTimeTo[0]
        weekTimes[`end_min_${week}`] = workTimeTo[1]
      } else {
        weekTimes[`end_hour_${week}`] = '23'
        weekTimes[`end_min_${week}`] = '45'
      }
    }
  })
  return {
    search: {
      ...otherSearch,
      geocode: { ...geocodes }, // オブジェクトで送信しないとエラーになる
      station_group_code: stations,
      work_time_from: undefined,
      work_time_to: undefined,
    },
    regular_search: {
      ...regular_search,
      ...weekTimes,
    },
  }
}

export const executeRegularSearch = ({
  search,
  regular_search,
}: ISearchState) => {
  const basePath = '/regular_search'
  const query = buildRegularSearchQuery({ search, regular_search })

  location.href = `${basePath}${qs.stringify(query, {
    addQueryPrefix: true,
    filter: (_, value: string | number | boolean) =>
      !value ? undefined : value,
    arrayFormat: 'brackets',
  })}`
}

export const getSearchParams = ({
  reserveType,
  search,
  regular_search,
}: ISearchState) => {
  let query: Record<string, unknown>
  if (reserveType === 'normal') {
    query = buildSearchQuery({ search })
  } else {
    query = buildRegularSearchQuery({ search, regular_search })
  }
  const queryString = qs.stringify(query, {
    filter: (_, value: string | number | boolean) =>
      !value ? undefined : value,
    arrayFormat: 'brackets',
  })
  return qs.parse(queryString)
}

export const buildSearchQeuryString = ({
  reserveType,
  search,
  regular_search,
}: ISearchState) => {
  let query: Record<string, unknown>
  if (reserveType === 'normal') {
    query = buildSearchQuery({ search })
  } else {
    query = buildRegularSearchQuery({ search, regular_search })
  }
  return qs.stringify(query, {
    filter: (_, value: string | number | boolean) =>
      !value ? undefined : value,
    arrayFormat: 'brackets',
  })
}

export const makeSearchUrl = ({ search }: ISearchState) => {
  const basePath = '/search'
  const query = buildSearchQuery({ search })

  return `${basePath}${qs.stringify(query, {
    addQueryPrefix: true,
    filter: (_, value: string | number | boolean) =>
      !value ? undefined : value,
    arrayFormat: 'brackets',
  })}`
}

export const makeTodaySearchUrl = (searchType: ISearchType) => {
  if (searchType === 'sitter') {
    return '/search/sitter_today'
  }
  const today = new Date()
  return makeSearchUrl({
    search: { search_type: searchType, work_date_from: today },
  })
}

export const makeTomorrowSearchUrl = (searchType: ISearchType) => {
  if (searchType === 'sitter') {
    return '/search/sitter_tomorrow'
  }
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  return makeSearchUrl({
    search: { search_type: searchType, work_date_from: tomorrow },
  })
}
