import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import { TimePicker } from '~/atoms/search/TimePicker'

import * as Style from './style.css'

export interface IProps {
  timeFrom: string
  timeTo: string
  submitLabel: string
  setTimeFromHandler: (timeString: string) => void
  setTimeToHandler: (timeString: string) => void
  resetHandler: () => void
  submitHandler: () => void
  closeHandler: () => void
}

export const SearchTime = ({
  timeFrom,
  timeTo,
  submitLabel,
  setTimeFromHandler,
  setTimeToHandler,
  resetHandler,
  submitHandler,
  closeHandler,
}: IProps): JSX.Element => {
  return (
    <div className={Style.SearchTime}>
      <FontAwesomeIcon
        icon={faXmark}
        className={Style.SearchTimeClose}
        onClick={closeHandler}
      />
      <div className={Style.SearchTimeWrapper}>
        <p className={Style.SearchTimeLabel}>サポート時間</p>
        <ul className={Style.SearchTimeInput}>
          <li className={Style.SearchTimeSelecter}>
            <label className={Style.SearchTimeSelecterLabel}>開始時間</label>
            <div>
              <TimePicker
                label={!timeFrom ? '指定しない' : timeFrom}
                defaultHour={timeFrom.slice(0, 2)}
                defaultMinute={timeFrom.slice(3, 5)}
                setTimeHandler={setTimeFromHandler}
              />
            </div>
          </li>
          <li className={Style.SearchTimeBetweenLabel}>～</li>
          <li className={Style.SearchTimeSelecter}>
            <label className={Style.SearchTimeSelecterLabel}>終了時間</label>
            <div>
              <TimePicker
                timePickerToStyle={true}
                label={!timeTo ? '指定しない' : timeTo}
                defaultHour={timeTo.slice(0, 2)}
                defaultMinute={timeTo.slice(3, 5)}
                setTimeHandler={setTimeToHandler}
              />
            </div>
          </li>
        </ul>
        <div className={Style.SearchTimeActions}>
          <span className={Style.SearchTimeReset} onClick={resetHandler}>
            リセット
          </span>
          <span className={Style.SearchTimeSubmit} onClick={submitHandler}>
            {submitLabel}
          </span>
        </div>
      </div>
    </div>
  )
}
