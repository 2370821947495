import qs from 'qs'

import * as client from '@/utils/restClient'
import { buildSearchQuery, buildRegularSearchQuery } from '@/utils/search'

export const getAreasAndStations = (area_id: number | string) => {
  const params = {
    area_id,
  }
  return client.get<IGeocodesAndStations>(
    '/search_location_prefecture_area_and_station_json',
    params,
  )
}

export const getSearchCount = ({ search }: ISearchState) => {
  const query = buildSearchQuery({ search })
  // 通常のクエリパラメータにするとエラーになるため、自前でクエリに変換する
  const url = `/search_count${qs.stringify(query, {
    addQueryPrefix: true,
    filter: (_, value: string | number | boolean) =>
      !value ? undefined : value,
    arrayFormat: 'brackets',
  })}`
  return client.get<ISearechTotalCount>(url)
}

export const getRegularSearchCount = ({
  search,
  regular_search,
}: ISearchState) => {
  const query = buildRegularSearchQuery({ search, regular_search })
  // 通常のクエリパラメータにするとエラーになるため、自前でクエリに変換する
  const url = `/regular_search_count${qs.stringify(query, {
    addQueryPrefix: true,
    filter: (_, value: string | number | boolean) =>
      !value ? undefined : value,
    arrayFormat: 'brackets',
  })}`
  return client.get<ISearechTotalCount>(url)
}
