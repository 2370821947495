import * as React from 'react'

import { SearchConditionPrefecture as Presenter, IProps } from './presenter'
import {
  trackingUserContext,
  searchEventIdContext,
} from '@/contexts/trackingContexts'
import { trackEvent } from '@/utils/analyze'

export const SearchConditionPrefecture = (props: IProps): JSX.Element => {
  const user = React.useContext(trackingUserContext)
  const eventIds = React.useContext(searchEventIdContext)

  /*------------------ 表示イベントの計測 ------------------*/
  React.useEffect(() => {
    trackEvent(eventIds.SEARCH_MODAL_PREFECTURE_VIEW, user)
  }, [props.prefectures])

  return <Presenter {...props} />
}

export default SearchConditionPrefecture
