import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { Swiper } from 'swiper/types'
import { register } from 'swiper/element/bundle'

import { findIndexWithArray } from '@/utils/arrayUtil'

import * as Style from './style.css'

const SWIPER_SPEED = 200
const DEFAULT_SWIPER_SETTING = {
  init: false,
  'slide-to-clicked-slide': true,
  direction: 'vertical' as const,
  mousewheel: true,
  'slides-per-view': 7,
  loop: true,
  'loop-additional-slides': 7,
  'centered-slides': true,
  style: { height: '200px' },
  speed: SWIPER_SPEED,
}
const HOUR_RANGE = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
]
/* 仕様上slidesPerViewの倍以上の要素が必要 */
const MINUTE_RANGE = [
  '00',
  '15',
  '30',
  '45',
  '00',
  '15',
  '30',
  '45',
  '00',
  '15',
  '30',
  '45',
  '00',
  '15',
  '30',
  '45',
  '00',
  '15',
  '30',
  '45',
  '00',
  '15',
  '30',
  '45',
  '00',
  '15',
  '30',
  '45',
  '00',
  '15',
  '30',
  '45',
]

export interface IProps {
  timePickerToStyle?: boolean
  label: string
  defaultHour: string
  defaultMinute: string
  setTimeHandler: (timeString: string) => void
}

export const TimePicker = ({
  timePickerToStyle = false,
  label,
  defaultHour,
  defaultMinute,
  setTimeHandler,
}: IProps): JSX.Element => {
  const hourSwiperRef = React.useRef<SwiperRef>(undefined)
  const minuteSwiperRef = React.useRef<SwiperRef>(undefined)
  const timePickerWrapperClassName = timePickerToStyle
    ? Style.TimePickerWrapperToEdit
    : Style.TimePickerWrapperEdit
  const [isEdit, setEdit] = React.useState(false)
  const initialHourIndex = findIndexWithArray(defaultHour, HOUR_RANGE)
  const initialMinuteIndex = findIndexWithArray(defaultMinute, MINUTE_RANGE)
  const [currentHourIndex, setHourIndex] = React.useState(initialHourIndex)
  const [currentminuteIndex, setMinuteIndex] =
    React.useState(initialMinuteIndex)

  const setInputHandler = (hourIndex: number, minuteIndex: number) => {
    const hour = HOUR_RANGE[hourIndex]
    const minute = MINUTE_RANGE[minuteIndex]
    setEdit(false)
    setTimeHandler(`${hour}:${minute}`)
  }

  const resetHanler = () => {
    setEdit(false)
    setTimeHandler('')
  }

  React.useEffect(() => {
    // Register Swiper web component
    register()

    // Add event listener
    hourSwiperRef.current.addEventListener(
      'swiperslidechange',
      (event: CustomEvent<[swiper: Swiper]>) => {
        // lintだとswiperの型が認識できないためチェックを無効にする
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,
        setHourIndex(event.detail[0].realIndex as number)
      },
    )
    minuteSwiperRef.current.addEventListener(
      'swiperslidechange',
      (event: CustomEvent<[swiper: Swiper]>) => {
        // lintだとswiperの型が認識できないためチェックを無効にする
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,
        setMinuteIndex(event.detail[0].realIndex as number)
      },
    )

    // initialize swiper
    hourSwiperRef.current.initialize()
    minuteSwiperRef.current.initialize()
  }, [])

  return (
    <div className={Style.TimePicker}>
      {isEdit && (
        <div
          className={Style.TimePickerEditBackgroud}
          onClick={() => setEdit(false)}
        ></div>
      )}
      <span className={Style.TimePickerLabel} onClick={() => setEdit(true)}>
        <span>{label}</span>
        <FontAwesomeIcon icon={faChevronDown} />
      </span>
      <div
        className={
          isEdit ? timePickerWrapperClassName : Style.TimePickerWrapper
        }
      >
        <div className={Style.TimePickerInput}>
          <span className={Style.TimePickerInputHour}>
            <swiper-container
              {...DEFAULT_SWIPER_SETTING}
              ref={hourSwiperRef}
              initial-slide={currentHourIndex}
            >
              {HOUR_RANGE.map((hour, index) => (
                <swiper-slide key={`hour-${hour}-${index}`}>
                  <span className={Style.TimePickerInputItem}>{hour}</span>
                </swiper-slide>
              ))}
            </swiper-container>
          </span>
          <span className={Style.TimePickerInputMinute}>
            <swiper-container
              {...DEFAULT_SWIPER_SETTING}
              ref={minuteSwiperRef}
              initial-slide={currentminuteIndex}
            >
              {MINUTE_RANGE.map((minute, index) => (
                <swiper-slide key={`minute-${minute}-${index}`}>
                  <span className={Style.TimePickerInputItem}>{minute}</span>
                </swiper-slide>
              ))}
            </swiper-container>
          </span>
        </div>
        <div className={Style.TimePickerControl}>
          <span className={Style.TimePickerReset} onClick={resetHanler}>
            指定しない
          </span>
          <span
            className={Style.TimePickerSet}
            onClick={() =>
              setInputHandler(currentHourIndex, currentminuteIndex)
            }
          >
            決定
          </span>
        </div>
      </div>
    </div>
  )
}
