import * as React from 'react'
import { createPortal } from 'react-dom'
import { UseFormGetValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ModalWrapper } from '~/atoms/modal/ModalWrapper'
import { SearchType } from '~/organisms/search/SearchType'
import {
  SearchCondition,
  makeSearchConditionSummaryText,
} from '~/organisms/search/SearchCondition'
import { SearchDate } from '~/organisms/search/SearchDate'
import { SearchTime } from '~/organisms/search/SearchTime'

// images
import SearchIcon from './search.svg?reactCompornt'
import CalendarIcon from './calendar.svg?reactCompornt'
import TimeIcon from './icon_time.svg?reactCompornt'

import * as Style from './style.css'

export interface IProps {
  todaySearchUrl: string
  tomorrowSearchUrl: string
  modalType: string
  prefectures: [string, string][]
  searchState: ISearchState
  setModal: (modalType: string) => void
  getValues: UseFormGetValues<ISearchState>
  setSearchTypeHandler: (searchType: ISearchType) => void
  searchConditionSubmitHandler: (state: ISearchConditionState) => void
  searchDateSubmitHandler: (state: ISearchDateState) => void
  searchTimeSubmitHandler: (state: ISearchTimeState) => void
  clickTodayLinkHandler: () => void
  clickTomorrowLinkHandler: () => void
  submitHandler: () => void
}

export const SearchForm = ({
  todaySearchUrl,
  tomorrowSearchUrl,
  modalType,
  prefectures,
  searchState,
  setModal,
  getValues,
  setSearchTypeHandler,
  searchConditionSubmitHandler,
  searchDateSubmitHandler,
  searchTimeSubmitHandler,
  clickTodayLinkHandler,
  clickTomorrowLinkHandler,
  submitHandler,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const minDate = new Date()
  const maxDate = new Date(minDate.getFullYear() + 2, 11, 31)
  const [isInitialized, setInitialized] = React.useState(false)
  React.useEffect(() => {
    setInitialized(true)
  }, [true])

  return (
    <div className={Style.SearchForm}>
      <div className={Style.SearchFormTop}>
        <p className={Style.SearchFormPurpose}>ご利用目的をお選びください</p>
        <div className={Style.SearchFormType}>
          <SearchType
            setSearchTypeHandler={setSearchTypeHandler}
            getValues={getValues}
          />
        </div>
        <div className={Style.SearchFormSummaries}>
          <div className={Style.SearchFormSummaryWrapper}>
            <div
              className={Style.SearchFormSummaryCondition}
              onClick={() => setModal('filter')}
            >
              <SearchIcon className={Style.SearchFormSummaryConditionIcon} />
              <span className={Style.SearchFormSummaryTextActive}>
                {makeSearchConditionSummaryText(
                  searchState.search,
                  searchState.regular_search,
                  prefectures,
                  searchState.search.geocode,
                  searchState.search.station,
                  searchState.reserveType,
                  t,
                )}
              </span>
            </div>
            <div
              className={
                searchState.reserveType !== 'regular'
                  ? Style.SearchFormSummaryDate
                  : Style.SearchFormSummaryDateDisabled
              }
              onClick={() =>
                searchState.reserveType !== 'regular' && setModal('day')
              }
            >
              <CalendarIcon className={Style.SearchFormSummaryDateIcon} />
              <span
                className={
                  searchState.search.work_date_from
                    ? Style.SearchFormSummaryTextActive
                    : Style.SearchFormSummaryText
                }
              >
                {searchState.search.work_date_from
                  ? searchState.search.work_date_from.getFullYear().toString() +
                    '年' +
                    (
                      searchState.search.work_date_from.getMonth() + 1
                    ).toString() +
                    '月' +
                    searchState.search.work_date_from.getDate().toString() +
                    '日'
                  : '日付'}
              </span>
            </div>
            <div
              className={Style.SearchFormSummaryTime}
              onClick={() => setModal('time')}
            >
              <TimeIcon className={Style.SearchFormSummaryTimeIcon} />
              <span
                className={
                  searchState.search.work_time_from ||
                  searchState.search.work_time_to
                    ? Style.SearchFormSummaryTextActive
                    : Style.SearchFormSummaryText
                }
              >
                {searchState.search.work_time_from ||
                searchState.search.work_time_to
                  ? ''
                  : '時間'}
                {searchState.search.work_time_from}
                {!searchState.search.work_time_from &&
                searchState.search.work_time_to
                  ? '指定しない'
                  : ''}
                {searchState.search.work_time_from ||
                searchState.search.work_time_to
                  ? ' - '
                  : ''}
                {searchState.search.work_time_to}
                {searchState.search.work_time_from &&
                !searchState.search.work_time_to
                  ? '指定しない'
                  : ''}
              </span>
            </div>
          </div>
          <button
            className={Style.SearchFormSubmit}
            onClick={() => submitHandler()}
          >
            検索
          </button>
        </div>
      </div>
      {isInitialized &&
        createPortal(
          <>
            <ModalWrapper
              mobileAnimation="slideUp"
              isOpen={modalType === 'filter'}
            >
              <div className={Style.SearchFormModalLarge}>
                <SearchCondition
                  prefectures={prefectures}
                  getValues={getValues}
                  submitHandler={searchConditionSubmitHandler}
                  closeHandler={() => setModal('')}
                  submitLabel="決定"
                />
              </div>
            </ModalWrapper>
            <ModalWrapper mobileAnimation="fadeIn" isOpen={modalType === 'day'}>
              <div className={Style.SearchFormModalSmall}>
                <SearchDate
                  getValues={getValues}
                  submitHandler={searchDateSubmitHandler}
                  closeHandler={() => setModal('')}
                  submitLabel="決定"
                  minDate={minDate}
                  maxDate={maxDate}
                />
              </div>
            </ModalWrapper>
            <ModalWrapper
              mobileAnimation="fadeIn"
              isOpen={modalType === 'time'}
            >
              <div className={Style.SearchFormModalSmall}>
                <SearchTime
                  submitLabel="決定"
                  getValues={getValues}
                  submitHandler={searchTimeSubmitHandler}
                  closeHandler={() => setModal('')}
                />
              </div>
            </ModalWrapper>
          </>,
          document.body,
        )}
      <div className={Style.SearchFormBottom}>
        <div className={Style.SearchFormLinks}>
          <a
            className={Style.SearchFormLink}
            onClick={clickTodayLinkHandler}
            href={todaySearchUrl}
            // href="/search/sitter_today"
          >
            今日きてくれる
          </a>
          <a
            className={Style.SearchFormLink}
            onClick={clickTomorrowLinkHandler}
            href={tomorrowSearchUrl}
            // href="/search/sitter_tomorrow"
          >
            明日きてくれる
          </a>
        </div>
      </div>
    </div>
  )
}
