import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu'
import 'react-horizontal-scrolling-menu/dist/styles.css'

import * as Style from './style.css'

export interface IProps<T> {
  searchTypes: { label: string; value: T }[]
  selectedSearchType: T
  setSearchTypeHandler: (sarchType: T) => void
}

const LeftArrow = (isInitialized: boolean) => {
  const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext)

  return (
    !isFirstItemVisible && (
      <div
        className={
          isInitialized
            ? Style.SearchTypeCtrlLeft
            : Style.SearchTypeCtrlLeftInitializing
        }
      >
        <FontAwesomeIcon
          icon={faChevronLeft}
          className={Style.SearchTypeCtrlIcon}
          onClick={() => scrollPrev()}
        />
      </div>
    )
  )
}

const RightArrow = (isInitialized: boolean) => {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext)

  return (
    !isLastItemVisible && (
      <div
        className={
          isInitialized
            ? Style.SearchTypeCtrlRight
            : Style.SearchTypeCtrlRightInitializing
        }
      >
        <FontAwesomeIcon
          icon={faChevronRight}
          className={Style.SearchTypeCtrlIcon}
          onClick={() => scrollNext()}
        />
      </div>
    )
  )
}

export const SearchType = <T,>({
  searchTypes,
  selectedSearchType,
  setSearchTypeHandler,
}: IProps<T>): JSX.Element => {
  const [isInitialized, setInitialized] = React.useState(false)

  return (
    <div className={Style.SearchType}>
      <ScrollMenu
        LeftArrow={() => LeftArrow(isInitialized)}
        RightArrow={() => RightArrow(isInitialized)}
        onInit={() => setInitialized(true)}
      >
        {searchTypes.map((searchType) => (
          <span
            key={`search-type-${searchType.value as string}`}
            className={
              selectedSearchType === searchType.value
                ? Style.SearchTypeItemActive
                : Style.SearchTypeItem
            }
            onClick={() => setSearchTypeHandler(searchType.value)}
          >
            {searchType.label}
          </span>
        ))}
      </ScrollMenu>
    </div>
  )
}
