import * as React from 'react'
import { useForm, UseFormGetValues } from 'react-hook-form'

import { SearchDate as Presenter, IProps as IPresenterProps } from './presenter'
import {
  trackingUserContext,
  searchEventIdContext,
} from '@/contexts/trackingContexts'
import { trackEvent } from '@/utils/analyze'

interface IProps
  extends Omit<
    IPresenterProps,
    | 'setDateHandler'
    | 'setTodayHandler'
    | 'setTomorrowHandler'
    | 'resetHandler'
    | 'submitHandler'
  > {
  getValues: UseFormGetValues<ISearchDateState>
  submitHandler: (state: ISearchDateState) => void
}

export const SearchDate = ({ getValues, ...props }: IProps): JSX.Element => {
  const user = React.useContext(trackingUserContext)
  const eventIds = React.useContext(searchEventIdContext)
  const selectDate = getValues('search.work_date_from')
  const useFormObject = useForm<ISearchDateState>({
    defaultValues: {
      search: {
        work_date_from: selectDate,
      },
    },
  })
  const { setValue, watch } = useFormObject
  const watchAllFields = watch()
  const localDateFrom = watchAllFields.search.work_date_from

  const setDateHandler = (date: Date) => {
    setValue('search.work_date_from', date)
  }

  const setTodayHandler = (date: Date) => {
    trackEvent(eventIds.SEARCH_MODAL_DATE_CLICK_TODAY, user)
    setDateHandler(date)
  }

  const setTomorrowHandler = (date: Date) => {
    trackEvent(eventIds.SEARCH_MODAL_DATE_CLICK_TOMORROW, user)
    setDateHandler(date)
  }

  const resetHandler = () => {
    setValue('search.work_date_from', undefined)
  }

  const submitHandler = () => {
    trackEvent(eventIds.SEARCH_MODAL_DATE_SUBMIT, user)
    props.submitHandler(watchAllFields)
  }

  const closeHandler = () => {
    trackEvent(eventIds.SEARCH_MODAL_DATE_CLOSE, user)
    props.closeHandler()
  }

  /*------------------ 表示イベントの計測 ------------------*/
  React.useEffect(() => {
    trackEvent(eventIds.SEARCH_MODAL_DATE_VIEW, user)
  }, [true]) // 表示時に1回だけ計測したいので、固定値を設定

  return (
    <Presenter
      {...props}
      selectedDate={localDateFrom}
      setDateHandler={setDateHandler}
      setTodayHandler={setTodayHandler}
      setTomorrowHandler={setTomorrowHandler}
      resetHandler={resetHandler}
      submitHandler={submitHandler}
      closeHandler={closeHandler}
    />
  )
}

export default SearchDate
