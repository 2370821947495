import * as React from 'react'
import { useForm } from 'react-hook-form'

import { SearchForm as Presenter, IProps as IPresenter } from './presenter'
import { initConditionStateWithSearchType } from '~/organisms/search/SearchCondition'
import {
  trackingUserContext,
  searchEventIdContext,
  ISearchEvents,
} from '@/contexts/trackingContexts'
import {
  executeSearch,
  executeRegularSearch,
  makeTodaySearchUrl,
  makeTomorrowSearchUrl,
} from '@/utils/search'
import {
  SEARCH_EVENT_NAME_PAGE_VIEW,
  SEARCH_EVENT_CATEGORY_TOP,
  SEARCH_EVENT_NAME_CLICK,
  SEARCH_EVENT_CATEGORY_TOP_SEARCH_CONDITION,
  SEARCH_EVENT_NAME_MODAL_VIEW,
  trackEvent,
} from '@/utils/analyze'

interface IProps extends IPresenter {
  user?: ITrackingUser
}

const eventIds: ISearchEvents = {
  SEARCH_FORM_VIEW: {
    name: SEARCH_EVENT_NAME_PAGE_VIEW,
    category: SEARCH_EVENT_CATEGORY_TOP,
  },
  SEARCH_FORM_CLICK_SEARCH_TYPE: {
    name: SEARCH_EVENT_NAME_CLICK,
    category: SEARCH_EVENT_CATEGORY_TOP_SEARCH_CONDITION,
    group: '利用目的',
  },
  SEARCH_FORM_SUBMIT: {
    name: SEARCH_EVENT_NAME_CLICK,
    category: SEARCH_EVENT_CATEGORY_TOP_SEARCH_CONDITION,
    label: '検索する',
  },
  SEARCH_FORM_CLICK_TODAY: {
    name: SEARCH_EVENT_NAME_CLICK,
    category: SEARCH_EVENT_CATEGORY_TOP_SEARCH_CONDITION,
    label: '今日きてくれる',
  },
  SEARCH_FORM_CLICK_TOMORROW: {
    name: SEARCH_EVENT_NAME_CLICK,
    category: SEARCH_EVENT_CATEGORY_TOP_SEARCH_CONDITION,
    label: '明日きてくれる',
  },
  // 日付選択モーダル
  SEARCH_MODAL_DATE_VIEW: {
    name: SEARCH_EVENT_NAME_MODAL_VIEW,
    category: SEARCH_EVENT_CATEGORY_TOP_SEARCH_CONDITION,
    group: '日付条件',
  },
  SEARCH_MODAL_DATE_CLICK_TODAY: {
    name: SEARCH_EVENT_NAME_CLICK,
    category: SEARCH_EVENT_CATEGORY_TOP_SEARCH_CONDITION,
    group: '日付条件',
    label: '今日',
  },
  SEARCH_MODAL_DATE_CLICK_TOMORROW: {
    name: SEARCH_EVENT_NAME_CLICK,
    category: SEARCH_EVENT_CATEGORY_TOP_SEARCH_CONDITION,
    group: '日付条件',
    label: '明日',
  },
  SEARCH_MODAL_DATE_CLOSE: {
    name: SEARCH_EVENT_NAME_CLICK,
    category: SEARCH_EVENT_CATEGORY_TOP_SEARCH_CONDITION,
    group: '日付条件',
    label: '閉じる',
  },
  SEARCH_MODAL_DATE_SUBMIT: {
    name: SEARCH_EVENT_NAME_CLICK,
    category: SEARCH_EVENT_CATEGORY_TOP_SEARCH_CONDITION,
    group: '日付条件',
    label: '決定',
  },
  // 時間選択モーダル
  SEARCH_MODAL_TIME_VIEW: {
    name: SEARCH_EVENT_NAME_MODAL_VIEW,
    category: SEARCH_EVENT_CATEGORY_TOP_SEARCH_CONDITION,
    group: '時間条件',
  },
  SEARCH_MODAL_TIME_CLOSE: {
    name: SEARCH_EVENT_NAME_CLICK,
    category: SEARCH_EVENT_CATEGORY_TOP_SEARCH_CONDITION,
    group: '時間条件',
    label: '閉じる',
  },
  SEARCH_MODAL_TIME_SUBMIT: {
    name: SEARCH_EVENT_NAME_CLICK,
    category: SEARCH_EVENT_CATEGORY_TOP_SEARCH_CONDITION,
    group: '時間条件',
    label: '決定',
  },
  // 検索条件モーダル
  SEARCH_MODAL_CONDITION_VIEW: {
    name: SEARCH_EVENT_NAME_MODAL_VIEW,
    category: SEARCH_EVENT_CATEGORY_TOP_SEARCH_CONDITION,
    group: '絞込み条件',
  },
  SEARCH_MODAL_CONDITION_CLOSE: {
    name: SEARCH_EVENT_NAME_CLICK,
    category: SEARCH_EVENT_CATEGORY_TOP_SEARCH_CONDITION,
    group: '絞込み条件',
    label: '閉じる',
  },
  SEARCH_MODAL_CONDITION_RESET: {
    name: SEARCH_EVENT_NAME_CLICK,
    category: SEARCH_EVENT_CATEGORY_TOP_SEARCH_CONDITION,
    group: '絞込み条件',
    label: '条件クリア',
  },
  SEARCH_MODAL_CONDITION_SUBMIT: {
    name: SEARCH_EVENT_NAME_CLICK,
    category: SEARCH_EVENT_CATEGORY_TOP_SEARCH_CONDITION,
    group: '絞込み条件',
    label: '決定',
  },
  // 都道府県モーダル
  SEARCH_MODAL_PREFECTURE_VIEW: {
    name: SEARCH_EVENT_NAME_MODAL_VIEW,
    category: SEARCH_EVENT_CATEGORY_TOP_SEARCH_CONDITION,
    group: 'エリア条件_都道府県',
  },
  // エリアモーダル
  SEARCH_MODAL_AREA_VIEW: {
    name: SEARCH_EVENT_NAME_MODAL_VIEW,
    category: SEARCH_EVENT_CATEGORY_TOP_SEARCH_CONDITION,
    group: 'エリア条件_市区町村',
  },
  // 駅モーダル
  SEARCH_MODAL_STATION_VIEW: {
    name: SEARCH_EVENT_NAME_MODAL_VIEW,
    category: SEARCH_EVENT_CATEGORY_TOP_SEARCH_CONDITION,
    group: 'エリア条件_駅',
  },
}

export const SearchForm = ({ user, ...props }: IProps): JSX.Element => {
  const [modalType, setModal] = React.useState('')

  const { getValues, setValue, watch } = useForm<ISearchState>({
    defaultValues: {
      reserveType: 'normal',
      search: {
        search_type: 'sitter',
        available_schedule: 1,
        geocode: {},
        station: {},
        work_date_from: undefined,
        work_time_from: '',
        work_time_to: '',
      },
    },
  })
  const watchAllFields = watch()

  const todaySearchUrl = makeTodaySearchUrl(watchAllFields.search.search_type)
  const tomorrowSearchUrl = makeTomorrowSearchUrl(
    watchAllFields.search.search_type,
  )

  const submitHandler = () => {
    trackEvent(eventIds.SEARCH_FORM_SUBMIT, user)

    const { search, regular_search, reserveType } = watchAllFields

    reserveType === 'normal'
      ? executeSearch({ search })
      : executeRegularSearch({ search, regular_search })
  }

  const setSearchTypeHandler = (searchType: ISearchType) => {
    initConditionStateWithSearchType(searchType, getValues, setValue)
  }

  /*------------------ 検索条件選択モーダル ------------------*/
  const searchConditionSubmitHandler = (state: ISearchConditionState) => {
    const search = { ...getValues('search'), ...state.search }
    const regulerSearch = {
      ...getValues('regular_search'),
      ...state.regular_search,
    }
    if (state.reserveType === 'regular') {
      search.work_date_from = undefined
    }
    setValue('reserveType', state.reserveType)
    setValue('search', search)
    setValue('regular_search', regulerSearch)
    setModal('')
  }

  /*------------------ 日付選択モーダル ------------------*/
  const searchDateSubmitHandler = (state: ISearchDateState) => {
    const search = { ...getValues('search'), ...state.search }
    setValue('search', search)
    setModal('')
  }

  /*------------------ 時間選択モーダル ------------------*/
  const searchTimeSubmitHandler = (state: ISearchTimeState) => {
    const search = { ...getValues('search'), ...state.search }
    setValue('search', search)
    setModal('')
  }

  /*------------------ 今日・明日来てくれるLINK ------------------*/
  const clickTodayLinkHandler = () => {
    trackEvent(eventIds.SEARCH_FORM_CLICK_TODAY, user)
  }

  const clickTomorrowLinkHandler = () => {
    trackEvent(eventIds.SEARCH_FORM_CLICK_TOMORROW, user)
  }

  /*------------------ 表示イベントの計測 ------------------*/
  React.useEffect(() => {
    trackEvent(eventIds.SEARCH_FORM_VIEW, user)
  }, [true]) // 1回だけ計測したいので、固定値を設定

  return (
    <searchEventIdContext.Provider value={eventIds}>
      <trackingUserContext.Provider value={user}>
        <Presenter
          {...props}
          todaySearchUrl={todaySearchUrl}
          tomorrowSearchUrl={tomorrowSearchUrl}
          modalType={modalType}
          searchState={watchAllFields}
          setModal={setModal}
          getValues={getValues}
          setSearchTypeHandler={setSearchTypeHandler}
          searchConditionSubmitHandler={searchConditionSubmitHandler}
          searchDateSubmitHandler={searchDateSubmitHandler}
          searchTimeSubmitHandler={searchTimeSubmitHandler}
          clickTodayLinkHandler={clickTodayLinkHandler}
          clickTomorrowLinkHandler={clickTomorrowLinkHandler}
          submitHandler={submitHandler}
        />
      </trackingUserContext.Provider>
    </searchEventIdContext.Provider>
  )
}

export default SearchForm
