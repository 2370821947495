import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import search from './ja/search.json'
export const defaultNS = 'search'

i18next
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    //fallbackLng: "ja",
    lng: 'ja',
    resources: {
      ja: {
        search,
      },
    },
    defaultNS,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })
  .catch(void 0)
