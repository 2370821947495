import * as React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

import { isMobileDisplay } from '@/utils/devise'

import * as Style from './style.css'

export interface IProps extends React.ComponentPropsWithoutRef<'div'> {
  animation?: 'fadeIn' | 'slideUp'
  mobileAnimation?: 'fadeIn' | 'slideUp'
  isOpen?: boolean
}

const FADE_IN_ANIMATION_SETTING = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.3 },
}
const SLIDE_UP_ANIMATION_SETTING = {
  initial: { y: '100%' },
  animate: { y: 0 },
  exit: { y: '100%' },
  transition: { duration: 0.3 },
}
// アニメーションなしでも表示のイベントをハンドリングできるように
// 0秒の意味のないイベントを設定
const NO_ANIMATION_SETTING = {
  animate: { z: 0 },
  exit: { z: 0 },
  transition: { duration: 0 },
}

export const ModalWrapper = ({
  animation,
  mobileAnimation,
  isOpen,
  ...props
}: IProps): JSX.Element => {
  let animationSetting
  switch (isMobileDisplay() ? mobileAnimation : animation) {
    case 'fadeIn':
      animationSetting = FADE_IN_ANIMATION_SETTING
      break
    case 'slideUp':
      animationSetting = SLIDE_UP_ANIMATION_SETTING
      break
    default:
      animationSetting = NO_ANIMATION_SETTING
  }

  // モーダル表示時にスクロールできないように背景を固定
  const animationStartHanler = () => {
    document.body.style.overflow = 'hidden'
  }
  const onExitComplete = () => {
    document.body.style.overflow = 'initial'
  }

  return (
    <AnimatePresence onExitComplete={onExitComplete}>
      {isOpen && (
        <div className={Style.ModalWrapper}>
          <motion.div
            className={Style.ModalContent}
            {...animationSetting}
            onAnimationStart={animationStartHanler}
          >
            {props.children}
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  )
}

export default ModalWrapper
