import * as React from 'react'
import * as classNames from 'classnames'
import DatePicker from 'react-datepicker'
import {
  addDays,
  getYear,
  getMonth,
  getDay,
  getWeeksInMonth,
  lastDayOfMonth,
  addMonths,
  subMonths,
} from 'date-fns'
import ja from 'date-fns/locale/ja'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import * as Style from './style.css'
import 'react-datepicker/dist/react-datepicker.css'
import './datepicker.css'

export interface IProps {
  submitLabel: string
  selectedDate?: Date
  minDate?: Date
  maxDate?: Date
  setDateHandler: (date: Date) => void
  setTodayHandler: (date: Date) => void
  setTomorrowHandler: (date: Date) => void
  resetHandler: () => void
  submitHandler: () => void
  closeHandler: () => void
}

export const SearchDate = ({
  setDateHandler,
  setTodayHandler,
  setTomorrowHandler,
  selectedDate,
  minDate,
  maxDate,
  submitLabel,
  resetHandler,
  submitHandler,
  closeHandler,
}: IProps): JSX.Element => {
  const today = new Date()
  const tomorrow = addDays(new Date(), 1)
  const years: number[] = []
  for (let i = minDate.getFullYear(); i <= maxDate.getFullYear(); i++) {
    years.push(i)
  }
  const MONTHS: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  const [isYearEdit, setYearEdit] = React.useState(false)
  const [isMonthEdit, setMonthEdit] = React.useState(false)
  const [forceUpdateCount, setForceUpdateCount] = React.useState(0)
  const [isForceUpdateCountUp, setIsForceUpdateCountUp] = React.useState(false)
  const [currentCalendarDate, setCurrentCalendarDate] = React.useState<Date>(
    typeof selectedDate !== 'undefined' ? selectedDate : minDate,
  )
  const isNextMonthRowUpCss = getDay(lastDayOfMonth(currentCalendarDate)) !== 6
  const isNextMonth2weekhideCss =
    getWeeksInMonth(lastDayOfMonth(currentCalendarDate)) === 6 ||
    (getWeeksInMonth(lastDayOfMonth(currentCalendarDate)) === 5 &&
      getDay(lastDayOfMonth(currentCalendarDate)) === 6)
  const currentCalendarDateHandler = (date: Date) => {
    setCurrentCalendarDate(date)
  }
  const setDateAfterEventHandler = (date: Date) => {
    setDateHandler(date)
    setCurrentCalendarDate(date)
  }
  const clickEditBackgroundHandler = () => {
    setYearEdit(false)
    setMonthEdit(false)
  }
  const forceUpdateCountUp = () => {
    const nextCount = forceUpdateCount
    setForceUpdateCount(nextCount + 1)
  }

  /*-------------- iOS16のレンダリング漏れ対策 --------------*/
  React.useEffect(() => {
    setIsForceUpdateCountUp(true)
    const timeoutId = setTimeout(() => {
      setIsForceUpdateCountUp(false)
    }, 200)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [forceUpdateCount])

  return (
    <div className={Style.SearchDate}>
      <FontAwesomeIcon
        icon={faXmark}
        className={Style.SearchDateClose}
        onClick={closeHandler}
      />
      <div
        className={classNames({
          [Style.SearchDatePicker]: true,
          [Style.NextMonthRowUpCss]: isNextMonthRowUpCss,
          [Style.NextMonth2weekhideCss]: isNextMonth2weekhideCss,
          [Style.ForceUpdateCss]: isForceUpdateCountUp,
        })}
      >
        <DatePicker
          onChange={() => void 0}
          selected={selectedDate}
          onSelect={(date: Date) => {
            setDateAfterEventHandler(date)
            forceUpdateCountUp()
          }}
          locale={ja}
          minDate={minDate}
          maxDate={maxDate}
          inline
          monthsShown={2}
          focusSelectedMonth={true}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div>
              <div className={Style.SearchDateSelecter}>
                <span
                  className={Style.SearchDatePickerSetDate}
                  onClick={() => {
                    changeYear(getYear(today))
                    changeMonth(getMonth(today))
                    setTodayHandler(today)
                    currentCalendarDateHandler(today)
                    forceUpdateCountUp()
                  }}
                >
                  今日
                </span>
                <span
                  className={Style.SearchDatePickerSetDate}
                  onClick={() => {
                    changeYear(getYear(tomorrow))
                    changeMonth(getMonth(tomorrow))
                    setTomorrowHandler(tomorrow)
                    currentCalendarDateHandler(tomorrow)
                    forceUpdateCountUp()
                  }}
                >
                  明日
                </span>
              </div>
              <div className={'datepicker__header__dropdown'}>
                <button
                  type="button"
                  className={
                    'react-datepicker__navigation react-datepicker__navigation--previous'
                  }
                  aria-label="Previous Month"
                  onClick={() => {
                    decreaseMonth()
                    currentCalendarDateHandler(subMonths(date, 1))
                    forceUpdateCountUp()
                  }}
                  style={
                    prevMonthButtonDisabled ? { visibility: 'hidden' } : null
                  }
                >
                  <span
                    className={
                      'react-datepicker__navigation-icon react-datepicker__navigation-icon--previous'
                    }
                  >
                    Previous Month
                  </span>
                </button>
                <button
                  type="button"
                  className={
                    'react-datepicker__navigation react-datepicker__navigation--next'
                  }
                  aria-label="Next Month"
                  onClick={() => {
                    increaseMonth()
                    currentCalendarDateHandler(addMonths(date, 1))
                    forceUpdateCountUp()
                  }}
                  style={
                    nextMonthButtonDisabled ? { visibility: 'hidden' } : null
                  }
                >
                  <span
                    className={
                      'react-datepicker__navigation-icon react-datepicker__navigation-icon--next'
                    }
                  >
                    Next Month
                  </span>
                </button>
                <div className={Style.SearchDateDropdownYear}>
                  <span
                    className={Style.SearchDateDropdownYearLabel}
                    onClick={() => {
                      setYearEdit(true)
                      forceUpdateCountUp()
                    }}
                  >
                    {getYear(date)}年
                  </span>
                  <div
                    className={
                      isYearEdit
                        ? Style.DateDropdownWrapperYearEdit
                        : Style.DateDropdownWrapper
                    }
                  >
                    {years.map((year, index) => (
                      <span
                        className={
                          year == getYear(date)
                            ? Style.SearchDateDropdownSelected
                            : Style.SearchDateDropdown
                        }
                        onClick={() => {
                          changeYear(year)
                          setYearEdit(false)
                          date.setFullYear(year)
                          currentCalendarDateHandler(date)
                          forceUpdateCountUp()
                        }}
                        key={`dropdown-year-index-${index}`}
                      >
                        {year}年
                      </span>
                    ))}
                  </div>
                </div>
                <div className={Style.SearchDateDropdownMonth}>
                  <span
                    className={Style.SearchDateDropdownMonthLabel}
                    onClick={() => setMonthEdit(true)}
                  >
                    {getMonth(date) + 1}月
                  </span>
                  <div
                    className={
                      isMonthEdit
                        ? Style.DateDropdownWrapperMonthEdit
                        : Style.DateDropdownWrapper
                    }
                  >
                    {MONTHS.map((month: number, index) => (
                      <span
                        className={
                          month == getMonth(date) + 1
                            ? Style.SearchDateDropdownSelected
                            : Style.SearchDateDropdown
                        }
                        onClick={() => {
                          changeMonth(MONTHS.indexOf(month))
                          setMonthEdit(false)
                          date.setMonth(MONTHS.indexOf(month))
                          currentCalendarDateHandler(date)
                          forceUpdateCountUp()
                        }}
                        key={`dropdown-month-index-${index}`}
                      >
                        {month}月
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        />
        <div className={Style.SearchDateActions}>
          <span className={Style.SearchDateReset} onClick={resetHandler}>
            リセット
          </span>
          <span className={Style.SearchDateSubmit} onClick={submitHandler}>
            {submitLabel}
          </span>
        </div>
      </div>
      {(isYearEdit || isMonthEdit) && (
        <span
          className={Style.SearchDateBackground}
          onClick={clickEditBackgroundHandler}
        ></span>
      )}
    </div>
  )
}
