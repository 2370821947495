//　エラーにならないように型定義をしておく
// https://github.com/vercel/next.js/discussions/20784
type WindowWithDataLayer = Window & {
  dataLayer: Record<string, unknown>[]
}

declare const window: WindowWithDataLayer

// イベント名
export const SEARCH_EVENT_NAME_PAGE_VIEW = 'page_view'
export const SEARCH_EVENT_NAME_MODAL_VIEW = 'modal_view'
export const SEARCH_EVENT_NAME_CLICK = 'click_指定'

// カテゴリ
export const SEARCH_EVENT_CATEGORY_TOP = 'TOP'
export const SEARCH_EVENT_CATEGORY_TOP_SEARCH_CONDITION = 'TOP_条件検索'
export const SEARCH_EVENT_CATEGORY_SEARCH_RESULT = '検索結果'
export const SEARCH_EVENT_CATEGORY_SEARCH_RESULT_SORT = '検索結果_ソート'
export const SEARCH_EVENT_CATEGORY_SEARCH_RESULT_SEARCH_CONDITION =
  '検索結果_条件検索'
export const SEARCH_EVENT_CATEGORY_SEARCH_AREA_RESULT = 'エリア検索結果'
export const SEARCH_EVENT_CATEGORY_SEARCH_AREA_RESULT_SORT =
  'エリア検索結果_ソート'
export const SEARCH_EVENT_CATEGORY_SEARCH_AREA_RESULT_SEARCH_CONDITION =
  'エリア検索結果_条件検索'
export const SEARCH_EVENT_CATEGORY_SEARCH_REGULAR_RESULT = '定期検索結果'
export const SEARCH_EVENT_CATEGORY_SEARCH_REGULAR_RESULT_SORT =
  '定期検索結果_ソート'
export const SEARCH_EVENT_CATEGORY_SEARCH_REGULAR_RESULT_SEARCH_CONDITION =
  '定期検索結果_条件検索'

type ISearchEventName =
  | typeof SEARCH_EVENT_NAME_PAGE_VIEW
  | typeof SEARCH_EVENT_NAME_MODAL_VIEW
  | typeof SEARCH_EVENT_NAME_CLICK
type ISearchEventCategory =
  | typeof SEARCH_EVENT_CATEGORY_TOP
  | typeof SEARCH_EVENT_CATEGORY_TOP_SEARCH_CONDITION
  | typeof SEARCH_EVENT_CATEGORY_SEARCH_RESULT
  | typeof SEARCH_EVENT_CATEGORY_SEARCH_RESULT_SORT
  | typeof SEARCH_EVENT_CATEGORY_SEARCH_RESULT_SEARCH_CONDITION
  | typeof SEARCH_EVENT_CATEGORY_SEARCH_AREA_RESULT
  | typeof SEARCH_EVENT_CATEGORY_SEARCH_AREA_RESULT_SORT
  | typeof SEARCH_EVENT_CATEGORY_SEARCH_AREA_RESULT_SEARCH_CONDITION
  | typeof SEARCH_EVENT_CATEGORY_SEARCH_REGULAR_RESULT
  | typeof SEARCH_EVENT_CATEGORY_SEARCH_REGULAR_RESULT_SORT
  | typeof SEARCH_EVENT_CATEGORY_SEARCH_REGULAR_RESULT_SEARCH_CONDITION

export interface ITrackEvent {
  name: ISearchEventName
  category: ISearchEventCategory
  group?: string
  label?: string
  items?: string[]
}

export const trackEvent = (event?: ITrackEvent, user?: ITrackingUser): void => {
  return
  /* TODO: 計測設定 */
  /*
  // eventが設定されていな場合は送信しない
  if (!event) {
    return
  }

  const { name, ...otherEventData } = event
  const trackingData = user
    ? {
        event_name: name,
        ...otherEventData,
        user_id: user.id,
        account_type: user.account_type,
      }
    : {
        event_name: name,
        ...otherEventData,
      }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(trackingData)
  */
}
