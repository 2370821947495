import * as React from 'react'
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form'

import {
  SearchConditionArea as Presenter,
  IProps as IPresenterProps,
  TabType,
} from './presenter'
import { getAreasAndStations } from '@/apis/search'
import {
  trackingUserContext,
  searchEventIdContext,
} from '@/contexts/trackingContexts'
import { trackEvent } from '@/utils/analyze'

export interface ISearchConditionAreaState {
  search: {
    geocode?: {
      [key: number]: IGeocode[]
    }
    station?: {
      [key: number]: IStation[]
    }
  }
}

interface IProps
  extends Omit<
    IPresenterProps,
    | 'currentTab'
    | 'geocodes'
    | 'stations'
    | 'isLogin'
    | 'selectedGeocodes'
    | 'selectedStations'
    | 'selectTabHandler'
    | 'selectGeocodeHandler'
    | 'selectAllGeocodeHandler'
    | 'deselectAllGeocodeHandler'
    | 'selectStationHandler'
  > {
  getValues: UseFormGetValues<ISearchConditionAreaState>
  setValue: UseFormSetValue<ISearchConditionAreaState>
}

export const SearchConditionArea = ({
  currentPrefecture,
  getValues,
  setValue,
  ...props
}: IProps): JSX.Element => {
  const user = React.useContext(trackingUserContext)
  const eventIds = React.useContext(searchEventIdContext)
  const [currentTab, setTab] = React.useState<TabType>('geocode')
  const [geocodes, setGeocodes] = React.useState<IGeocode[]>([])
  const [stations, setStations] = React.useState<IStation[]>([])
  const selectedGeocodes = getValues('search.geocode')
  const selectedStations = getValues('search.station')
  const selectGeocodeHandler = (prefectureId: number, geocode: IGeocode) => {
    if (
      selectedGeocodes[prefectureId]?.some(
        (s) => s.geocode_id === geocode.geocode_id,
      )
    ) {
      selectedGeocodes[prefectureId] = selectedGeocodes[prefectureId].filter(
        (s) => s.geocode_id !== geocode.geocode_id,
      )
      setValue('search.geocode', selectedGeocodes)
    } else {
      if (!selectedGeocodes[prefectureId]) {
        selectedGeocodes[prefectureId] = []
      }
      selectedGeocodes[prefectureId] = [
        ...selectedGeocodes[prefectureId],
        geocode,
      ]
      setValue('search.geocode', selectedGeocodes)
    }
  }

  const selectAllGeocodeHandler = () => {
    selectedGeocodes[currentPrefecture.id] = geocodes
    setValue('search.geocode', selectedGeocodes)
  }
  const deselectAllGeocodeHandler = () => {
    selectedGeocodes[currentPrefecture.id] = []
    setValue('search.geocode', selectedGeocodes)
  }

  const selectStationHandler = (prefectureId: number, station: IStation) => {
    if (selectedStations[prefectureId]?.some((s) => s.slug === station.slug)) {
      selectedStations[prefectureId] = selectedStations[prefectureId].filter(
        (s) => s.slug !== station.slug,
      )
      setValue('search.station', selectedStations)
    } else {
      if (!selectedStations[prefectureId]) {
        selectedStations[prefectureId] = []
      }
      selectedStations[prefectureId] = [
        ...selectedStations[prefectureId],
        station,
      ]
      setValue('search.station', selectedStations)
    }
  }

  React.useEffect(() => {
    ;(async () => {
      try {
        const res = await getAreasAndStations(currentPrefecture.id)

        if (res.result) {
          setGeocodes(res.res.geocodes)
          setStations(res.res.stations)
        }
      } catch {}
    })().catch(() => void 0) // エラー時の処理を行う場合は実装する
  }, [currentPrefecture])

  /*------------------ 表示イベントの計測 ------------------*/
  React.useEffect(() => {
    if (currentTab === 'geocode') {
      trackEvent(eventIds.SEARCH_MODAL_AREA_VIEW, user)
    } else {
      trackEvent(eventIds.SEARCH_MODAL_STATION_VIEW, user)
    }
  }, [currentTab])

  return (
    <Presenter
      currentTab={currentTab}
      selectTabHandler={setTab}
      currentPrefecture={currentPrefecture}
      selectedGeocodes={selectedGeocodes}
      selectedStations={selectedStations}
      geocodes={geocodes}
      stations={stations}
      isLogin={!!user}
      selectGeocodeHandler={selectGeocodeHandler}
      selectAllGeocodeHandler={selectAllGeocodeHandler}
      deselectAllGeocodeHandler={deselectAllGeocodeHandler}
      selectStationHandler={selectStationHandler}
      {...props}
    />
  )
}

export default SearchConditionArea
