import * as React from 'react'
import { ITrackEvent } from '@/utils/analyze'

// 計測用ユーザー情報
export const trackingUserContext = React.createContext<ITrackingUser>(undefined)

// 計測イベントID
export interface ISearchEvents {
  // 検索フォーム
  SEARCH_FORM_VIEW?: ITrackEvent
  SEARCH_FORM_CLICK_SEARCH_TYPE?: ITrackEvent
  SEARCH_FORM_SUBMIT?: ITrackEvent
  SEARCH_FORM_CLICK_TODAY?: ITrackEvent
  SEARCH_FORM_CLICK_TOMORROW?: ITrackEvent
  // 日付選択モーダル
  SEARCH_MODAL_DATE_VIEW?: ITrackEvent
  SEARCH_MODAL_DATE_CLICK_TODAY?: ITrackEvent
  SEARCH_MODAL_DATE_CLICK_TOMORROW?: ITrackEvent
  SEARCH_MODAL_DATE_CLOSE?: ITrackEvent
  SEARCH_MODAL_DATE_SUBMIT?: ITrackEvent
  // 時間選択モーダル
  SEARCH_MODAL_TIME_VIEW?: ITrackEvent
  SEARCH_MODAL_TIME_CLOSE?: ITrackEvent
  SEARCH_MODAL_TIME_SUBMIT?: ITrackEvent
  // 検索条件モーダル
  SEARCH_MODAL_CONDITION_VIEW?: ITrackEvent
  SEARCH_MODAL_CONDITION_CLOSE?: ITrackEvent
  SEARCH_MODAL_CONDITION_RESET?: ITrackEvent
  SEARCH_MODAL_CONDITION_SUBMIT?: ITrackEvent
  // 都道府県モーダル
  SEARCH_MODAL_PREFECTURE_VIEW?: ITrackEvent
  // エリアモーダル
  SEARCH_MODAL_AREA_VIEW?: ITrackEvent
  // 駅モーダル
  SEARCH_MODAL_STATION_VIEW?: ITrackEvent
  // 検索結果
  SEARCH_RESULT_VIEW?: ITrackEvent
  SEARCH_RESULT_FILTER_DELETE?: ITrackEvent
  SEARCH_RESULT_CLICK_ORDER?: ITrackEvent
  SEARCH_RESULT_CHANGE_ORDER?: ITrackEvent
}

export const searchEventIdContext = React.createContext<ISearchEvents>({})
